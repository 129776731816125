import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { GET_SURVEYS, STUDENT_SURVEYS } from "./services/graphql";
import SurveyContent from "./SurveyContent";
import moment from 'moment';


const isDateNotInPast = (dateString) => {
    const givenDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss UTC');
    const currentDate = moment.utc().startOf('day');
    if (!givenDate.isValid()) {
        console.log(`Invalid date: ${dateString}`);
        return false;
    }
    return givenDate.isSameOrAfter(currentDate);
};
const Survey = () => {

    const [activeSurvey, setActiveSurvey] = useState(null);

    const { data, loading, error } = useQuery(GET_SURVEYS, {
        onCompleted: data => {
            const found = data.surveys?.find(el => isDateNotInPast(el.endAt));
            setActiveSurvey(found);
        }
    });

    const { data: data2 } = useQuery(STUDENT_SURVEYS);

    if (loading) {
        return <p>Loading</p>
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.surveys && activeSurvey && data2?.currentStudent) {

        const { currentStudent } = data2;

        return (
            <SurveyContent
                currentStudent={currentStudent}
                survey={activeSurvey}
                studentSurvey={currentStudent.studentSurveys.find(s => s.survey?.id === activeSurvey?.id)}
            />
        )
    }
    return null;
}

export default Survey