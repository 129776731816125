import React, { useState } from "react";
import StartSurvey from "./components/StartSurvey";
import StudentSurvey from "./components/StudentSurvey";
import Summary from './components/Summary';

const SurveyContent = ({ currentStudent, survey, studentSurvey }) => {

    const [state, setState] = useState({
        nextQuestion: studentSurvey && studentSurvey.nextQuestion,
        //nextQuestion: survey.surveyQuestions[8],
        progress: studentSurvey && studentSurvey.progress
    });

    if (!studentSurvey) {
        return <StartSurvey setState={setState} currentStudent={currentStudent} survey={survey} />
    }

    if (state?.nextQuestion) {
        return (
            <>
                <StudentSurvey
                    progress={state.progress}
                    studentSurvey={studentSurvey}
                    nextQuestion={state.nextQuestion}
                    setState={setState}
                />
            </>
        )
    }
    if (!state?.nextQuestion && state.progress >= survey.surveyQuestions.length) {
        return <Summary studentSurvey={studentSurvey} />
    }

    return null;
}

export default SurveyContent;