import React, { useState, useEffect } from 'react';
import CardLocked from './CardLocked';
import CardBought from './CardBought';

import styles from '../Rewards.module.scss';
import cx from  'classnames';

const CardHolder = ({ selectedPack, card, userCard, i, canBuyWinner }) => {

    const [flipAnimation, setFlipAnimation] = useState(false);
    const [flipCard, setFlipCard] = useState();

    useEffect(() => {
        if (flipAnimation) {
            setTimeout(() => {
                setFlipCard(true);
            }, 100)
        }
    }, [flipAnimation, setFlipCard])
    
    return(
        <>
            {
                userCard && (
                    <div className={styles.rewardsItem}>
                        <div className={cx(styles.flipCard, {[styles.flipCardFlipped]:flipCard})}>
                            <div className={styles.flipCardInner}>
                                <div className={cx({[styles.flipCardBack]: flipAnimation})}>
                                    <CardBought i={i} card={card} print={true} />
                                </div>
                            </div>
                        </div>
                        </div>
                    
                )
            }
        </>
    )
}

export default CardHolder;