import React, { useState } from "react";
import styles from '../Survey.module.scss';
import { useMutation } from "@apollo/react-hooks";
import { START_STUDENT_SURVEY, STUDENT_SURVEYS } from '../services/graphql';
import startImg from '../../../images/survey/boomer_start.png';
import { Button, FormErrors } from '@axeedge/go-pupil-components';

const StartSurvey = ({ currentStudent, survey, setState }) => {

    const [formErrors, setFormErrors] = useState([]);

    const [createStudentSurvey, { loading }] = useMutation(START_STUDENT_SURVEY, {
        variables: {
            surveyId: survey.id,
            studentId: currentStudent.id
        },
        onCompleted: data => {
            if (data?.createStudentSurvey?.errors?.length > 0) {
                setFormErrors(data?.createStudentSurvey?.errors);
                return
            }
            if (data.createStudentSurvey?.studentSurvey?.id) {
                
                const { createStudentSurvey } = data;
                setState({
                    nextQuestion: createStudentSurvey.studentSurvey.nextQuestion,
                    progress: createStudentSurvey.studentSurvey.progress
                })
            }
        },
        refetchQueries: [{ query: STUDENT_SURVEYS }],
        awaitRefetchQueries: true
    })



    return (
        <>
            <h1 className="heavy u-text-center u-text-white u-m-base-3 u-p-base-3">{survey.title}</h1>
            <div className={styles.start}>
                <div className={styles.startMain}>
                    <div className={styles.startMainImg}><img src={startImg} alt='' /></div>
                    <div className={`${styles.panel} ${styles.panelArrowLeft} ${styles.startMainText}`}>
               

                        <p className="u-m-base-2">Welcome to the survey. Please answer as honestly as possible – there are no right or wrong answers.</p>

                        <p className="u-m-base-2">We want to find out about how you like to read including when, where and what you like reading.</p>
                        <p className="u-m-base-2">Your answers will build up a big picture about reading for pleasure.</p>
                        <p className="u-m-base-3 u-p-base-3">Once you submit your survey, you will be awarded 10 extra gems!!</p>

                        {formErrors && <FormErrors errors={formErrors} />}
                        <Button onClick={createStudentSurvey} disabled={loading} className="button--full-width">Start the survey</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StartSurvey;