import React, { useState } from "react";
import { SURVEY_STATUS } from '../../services/constants';
import { CHECK_SURVEYS, CHECK_STUDENT_SURVEYS } from '../Survey/services/graphql';
import { useQuery } from "@apollo/react-hooks";
import { Link } from "@reach/router";
import moment from 'moment';
import survey from '../../images/survey/survey.png';
import styles from './Dashboard.module.scss';


const isDateNotInPast = (dateString) => {
    const givenDate = moment.utc(dateString, 'YYYY-MM-DD HH:mm:ss UTC');
    const currentDate = moment.utc().startOf('day');
    if (!givenDate.isValid()) {
        console.log(`Invalid date: ${dateString}`);
        return false;
    }
    return givenDate.isSameOrAfter(currentDate);
};

const SurveyBanner = () => {

    const [activeSurvey, setActiveSurvey] = useState(null);

    const { data } = useQuery(CHECK_SURVEYS, {
        onCompleted: data => {
            const found = data.surveys?.find(el => isDateNotInPast(el.endAt));
            setActiveSurvey(found);
        }
    });
    const { data: data2 } = useQuery(CHECK_STUDENT_SURVEYS);

    if (data?.surveys && activeSurvey && data2?.currentStudent) {
        const { currentStudent } = data2;
        const foundStudentSurvey = currentStudent.studentSurveys?.find(s => s?.survey?.id === activeSurvey?.id)

        return (
            <>
                {activeSurvey && (!foundStudentSurvey || foundStudentSurvey?.status !== SURVEY_STATUS.completed) &&
                    <Link to='/survey' className={styles.surveyInit}>
                        <p>Take our {activeSurvey.title}</p>
                        <img src={survey} className={styles.surveyInitImg} alt={'survey'} />
                    </Link>
                }

               { foundStudentSurvey && foundStudentSurvey?.status === SURVEY_STATUS.completed &&
                    <div className={styles.surveyInit}>
                        <p className="u-m-right-2">Survey completed!</p>
                        <img width={100} src={survey} className={styles.surveyInitImg} alt={'survey'} />
                    </div>
               }

            </>
        )
    }
    return null;
}

export default SurveyBanner;