import React, { useState } from "react";
import Progress from "./Progress";
import ImageFadeIn from "react-image-fade-in";
import { ADD_STUDENT_RESPONSE } from "../services/graphql";
import { useMutation } from "@apollo/react-hooks";
import { Button, FormErrors } from "@axeedge/go-pupil-components";
import genericImg from '../../../images/generic.png';

import styles from '../Survey.module.scss';

const Q_TYPE = {
    single: 1,
    multiple: 2
}

const StudentSurvey = ({ nextQuestion, progress, setState, studentSurvey }) => {

    const [selectedOpts, setSelectedOpts] = useState([]);

    const [formErrors, setFormErrors] = useState([]);

    const toggleChoice = (choice) => {
        if (nextQuestion.questionType === Q_TYPE.multiple) {
            if (selectedOpts.includes(choice.id)) {
                setSelectedOpts(prevOpts => [...prevOpts.filter(opt => opt !== choice.id)])
            } else {
                setSelectedOpts(prevOpts => [...prevOpts, choice.id])
            }
        } else if (nextQuestion.questionType === Q_TYPE.single) {
            setSelectedOpts([choice.id])
        }
    }

    const [addStudentResponse, { loading }] = useMutation(ADD_STUDENT_RESPONSE, {
        onCompleted: data => {
            if (data?.addStudentResponse?.errors?.length > 0) {
                setFormErrors(data.addStudentResponse.errors)
                return
            }
            if (data.addStudentResponse.studentSurvey) {
                const { addStudentResponse } = data;
                setSelectedOpts([]);
                setFormErrors([]);
                setState({
                    nextQuestion: addStudentResponse.studentSurvey.nextQuestion,
                    progress: addStudentResponse.studentSurvey.progress
                });
            }
        }
    })

    return (
        <div className={styles.surveyWrapper}>
            <Progress studentSurvey={studentSurvey} progress={progress} />
            <div className={styles.qTitle}>
                {nextQuestion.questionText}
            </div>
            <div className={`${styles.choices} ${nextQuestion.questionChoices.length > 5 ? styles.choices2Cols : ''}`}>
                {nextQuestion.questionChoices.map(choice => (
                    <div
                        key={choice.id}
                        onClick={() => toggleChoice(choice)}
                        className={`${styles.choice} ${selectedOpts.find(opt => opt === choice.id) ? styles.choiceActive : ''}`}
                    >
                        <div>
                            {nextQuestion.orderId >= 7 ?
                                <ImageFadeIn src={genericImg} alt='Image for choice' />
                                :
                                <img
                                    src={`../../images/survey/q_${nextQuestion.orderId}/${choice.orderId}.png`}
                                    alt='Image for choice'
                                    onError={(event) => {
                                        if (event.target instanceof HTMLImageElement) {
                                            event.target.src = genericImg;
                                        }
                                    }}
                                />
                            }
                        </div>
                        <p className={styles.choiceText}>{choice.answerText}</p>
                    </div>
                ))}
            </div>

            {formErrors && <FormErrors errors={formErrors} />}

            <div className="u-m-top-3 u-text-center">
                <Button
                    disabled={selectedOpts.length === 0 || loading}
                    className={styles.nextBtn}
                    onClick={() => {
                        addStudentResponse({
                            variables: {
                                surveyQuestionId: nextQuestion.id,
                                studentSurveyId: studentSurvey.id,
                                questionChoiceIds: [...selectedOpts]
                            }
                        })
                    }}
                >Next</Button></div>
        </div>
    )
}

export default StudentSurvey;