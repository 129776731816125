import React from "react";
import startImg from '../../../images/survey/boomer_start.png';
import styles from '../Survey.module.scss';

const Summary = ({ studentSurvey }) => {

    return (
        <>
            <h1 className="heavy u-text-center u-text-white u-m-base-3 u-p-base-3">{studentSurvey.survey.title}</h1>
            <div className={styles.start}>
                <div className={`${styles.startMain} ${styles.startMainAnswers}`}>
                    <div className={styles.startMainImg}><img src={startImg} alt='' /></div>
                    <div className={`${styles.panel} ${styles.panelArrowLeftTop} ${styles.startMainText}`}>
                        <h2 className={styles.startHeading}>Thanks for taking part!</h2>
                        <p className="u-m-base-3">Here’s how you anwered:</p>
                        {studentSurvey.studentResponses?.length > 0 &&
                            studentSurvey.studentResponses.map(response =>
                                <div key={response.id} className="u-m-base-3">
                                    <p className="u-m-base-1">Q: {response.surveyQuestion?.questionText}</p>
                                    <p>A: {response.questionChoices.map(choice => choice.answerText).join(', ')}</p>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>

    )
}

export default Summary;