import React from "react";
import img from '../../../images/survey/survey_progress.png';
import styles from '../Survey.module.scss';

const Progress = ({ progress, studentSurvey }) => {

    return (
        <>
            <div className={styles.progress}>
                <img src={img} className={styles.progressImg} alt='' />
                <p>{studentSurvey?.survey.title} - Question: {progress + 1}/{studentSurvey.survey?.surveyQuestions?.length}</p>
            </div>
        </>
    )
}

export default Progress;